import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import ProjectImg from '../Image/ProjectImg';
import uuidv1 from "uuid/v1";

const Projects = () => {
  // const { projects } = useContext(PortfolioContext);
  const projects = [
    {
      id: uuidv1(),
      img: "ecological_literacy.jpg",
      title: "Ecological Literacy",
      info: "I am actively learning about permaculture, foraging, and reducing my individual waste. As a whole I'm interested in farming concepts such as biodynamic farming practices, soil cultivation, urban farming, aquaponics, and hydroponics. I am an advocate of sustainable architecture, renewable energy, and reducing waste on a collective level (e.g. circular economies). Ultimately, I believe in the importance of regenerative systems and local resilience.",
      info2: "We do not inherit the earth from our ancestors, we borrow it from our descendants.",
      url: "",
      repo: "",
    },
    {
      id: uuidv1(),
      img: "gesamtkunstwerk.jpg",
      title: "Life as a Gesamtkunstwerk",
      info: "The phrase \"gesamtkunstwerk\" is a German word meaning \"total work of art\". Richard Wagner, with whom the concept is associated, used it to describe works of art that span multiple genres, e.g. visual art, music, theatre, etc. I believe the definition can be taken one step further and applied to life itself. After all, what is life if not a performance art piece? What is growth if not continuous process art? Life is the ultimate work of art. Spacetime is the canvas, energy is the medium, and we paint with each thought, emotion, and action.",
      info2: "Some art genres and movements that I find particularly influential are: Bauhaus, land art, site-specific installations/interventions, California Light & Space, Italian futurism, minimalism, pointillism, kinetic sculpture, process art, and Joseph Beuys' happenings.",
      url: "",
      repo: "",
    },
    {
      id: uuidv1(),
      img: "energy_medicine.jpg",
      title: "Holistic Medicine",
      info: "My personal practice includes meditation, energy work, yoga, shamanic journeying, plant medicine, sound therapy, and crystal healing. Some additional topics of interest are indigenous wisdom, biophotons, epigenetics, personalized medicine & nutrition, herbalism, Traditional Chinese Medicine, and qigong.",
      info2: "I am particularly interested in conducting experiments and scientific inquiries into the effects of the above. I don't need proof to validate my truth, but it certainly helps an idea gain traction.",
      url: "",
      repo: "",
    },
    {
      id: uuidv1(),
      img: "hummingbird.jpg",
      title: "Social Enterprise and Impact",
      info: "A successful business model allows one to make a good living AND make a positive impact. It's not just about what's done, but how it gets done. My ethos is non-violent communication and servant leadership - applied by both individuals and organizations.",
      info2: "Some causes that are dear to me: indigenous rights, food security, individualized education, open data, the metaverse, universal basic income & healthcare, sustainability, and planetary stewardship.",
      url: "",
      repo: "",
    },
  ];

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="projects">
      <Container>
        <div className="project-wrapper">
          <Title title="Interests & Ideas" />
          {projects.map(project => {
            const { id, title, info, info2, url, repo, img } = project;

            return (
              <Row key={id}>
                <Col lg={4} sm={12}>
                  <Fade
                    left={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={500}
                    distance="30px"
                  >
                    <div className="project-wrapper__text">
                      <h3 className="project-wrapper__text-title">{title || 'Project Title'}</h3>
                      <div>
                        <p>
                          {info ||
                            'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Excepturi neque, ipsa animi maiores repellendu distinctioaperiam earum dolor voluptatum consequatur blanditiis inventore debitis fuga numquam voluptate architecto itaque molestiae.'}
                        </p>
                        <p className="mb-4">{info2 || ''}</p>
                      </div>
                      {/* <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cta-btn cta-btn--hero"
                        href={url || '#!'}
                      >
                        See Live
                      </a> */}

                      {/* {repo && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn text-color-main"
                          href={repo}
                        >
                          Source Code
                        </a>
                      )} */}
                    </div>
                  </Fade>
                </Col>
                <Col lg={8} sm={12}>
                  <Fade
                    right={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={1000}
                    distance="30px"
                  >
                    <div className="project-wrapper__image">
                      {/* <a
                        href={url || '#!'}
                        target="_blank"
                        aria-label="Project Link"
                        rel="noopener noreferrer"
                      > */}
                          <div data-tilt className="thumbnail rounded">
                            <ProjectImg alt={title} filename={img} />
                          </div>
                      {/* </a> */}
                    </div>
                  </Fade>
                </Col>
              </Row>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default Projects;
