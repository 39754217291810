import uuidv1 from "uuid/v1";

// HEAD DATA
export const headData = {
  title: "Wu Projects", // e.g: "Name | Developer"
  lang: "en", // e.g: en, es, fr, jp
  description: "Shen-Shen Wu: Futurist", // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: "Shen-Shen is a",
  name: "futurist",
  subtitle: "who cares about the wellness of people, communities, and ecosystems.",
  cta: "Continue",
};

// ABOUT DATA
export const aboutData = {
  img: "shen-shen.png",
  paragraphOne: "Artist-Activist. Coder-Curator. Photographer-Writer. Taiwanese-Canadian. I was destined to be a hyphenate.",
  paragraphTwo: "I am driven by my love of adventure, curiosity about consciousness, and respect for both science and spirituality. I believe that everything is connected - so, the best innovation lies at the intersection of art and technology.",
  paragraphThree: "I have an open mind, clear vision, and fire in my heart. I'm here to make the world a better place.",
  resume: "", // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: uuidv1(),
    img: "ecological_literacy.jpg",
    title: "Ecological Literacy",
    info: "I am actively learning about permaculture, foraging, and reducing my individual waste. I am also interested in biodynamic farming, soil cultivation, ecovillages, tiny homes, urban farming, aquaponics/hydroponics, renewable energy, and reducing waste on a collective level (e.g. circular economies). Ultimately, I believe in the importance of sustainability and local resilience.",
    info2: "We do not inherit the earth from our ancestors, we borrow it from our descendants.",
    url: "",
    repo: "",
  },
  {
    id: uuidv1(),
    img: "gesamtkunstwerk.jpg",
    title: "Life as a Gesamtkunstwerk",
    info: "The phrase 'gesamtkunstwerk' is a German word meaning 'total work of art'. Attributed to Richard Wagner, he used it to describe works of art that span multiple genres, e.g. visual art, music, theatre, etc. I believe the definition can be taken one step further and applied to life itself. After all, what is life if not a performance art piece? What is growth if not continuous process art? Life is the ultimate work of art. Spacetime is the canvas, energy is the medium, and we paint with each thought, emotion, and action.",
    info2: "Some art genres and movements that I find particularly influential are: pointillism, Italian futurism, minimalism, land art, kinetic sculpture, California Light & Space, process art, site-specific installations/interventions, and Joseph Beuys' happenings.",
    url: "",
    repo: "",
  },
  {
    id: uuidv1(),
    img: "energy_medicine.jpg",
    title: "Energy Medicine",
    info: "My personal practice includes meditation, shamanic journeying, journaling, yoga, energy work, psychedelics, sound therapy, and crystal healing. Some additional topics of interest are indigenous wisdom, biophotons, epigenetics, personalized nutrition/medicine, herbalism, Traditional Chinese Medicine, and qigong.",
    info2: "I am particularly interested in conducting experiments and scientific inquiries into the impact of the above. I don't need proof to validate my truth - but it certainly helps an idea gain traction.",
    url: "",
    repo: "",
  },
  {
    id: uuidv1(),
    img: "hummingbird.jpg",
    title: "Social Enterprise and Impact",
    info: "The ideology of capitalism has impacted our global economy in beneficial ways. Now, it's time for it to become an agent of positive societal change. I believe that if an idea allows one to make a living while doing good, it will gain traction. The path just needs to be laid - the trail is already blazed.",
    info2: "Some causes that I hold dear: the metaverse, indigenous rights, food security, open data, universal basic income and healthcare, planetary stewardship, and non-violence.",
    url: "",
    repo: "",
  },
];

// CONTACT DATA
export const contactData = {
  cta: "I'd love to connect with you.",
  btn: "Email me",
  email: "hello@wuprojects.com",
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: uuidv1(),
      name: "linkedin",
      url: "https://www.linkedin.com/in/wushenshen",
    },
    {
      id: uuidv1(),
      name: "github",
      url: "http://github.com/wushenshen",
    },
  ],
};
